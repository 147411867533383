import * as React from "react"
import { graphql } from 'gatsby'

// Hooks

// COmponents
import { Page } from 'components/core/layouts/base';
import { Hero404 } from 'components/core/hero';
import { Footer } from 'components/core/footer';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

function NotFoundPage({location}){
  const {t} = useTranslation("service");
  return <Page dark={true} location={location} title={t("metadata.title")} description={t("metadata.description")}>
          <div className="flex flex-col h-full min-h-screen bg-red-100">
            <div className="flex-1 h-full bg-[#354178]">
              <Hero404/>
            </div>
            <Footer className=" w-full bg-none" />
          </div>
          </Page>
}

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;